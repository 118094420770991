<template>
  <th-wrapper :title="$t('pages.settings.general.generation_rules.title')">
    <th-input-title
      id="customer-creation"
      :title="
        $t('pages.settings.general.generation_rules.customer_creation.title')
      "
    />

    <el-radio-group
      v-model="customerCreation"
      aria-labelledby="customer-creation"
      class="flex flex-col mt-2"
    >
      <el-radio label="false">
        {{
          $t(
            'pages.settings.general.generation_rules.customer_creation.options.false'
          )
        }}
      </el-radio>
      <el-radio label="true">
        {{
          $t(
            'pages.settings.general.generation_rules.customer_creation.options.true'
          )
        }}
      </el-radio>
      <el-radio label="optional">
        {{
          $t(
            'pages.settings.general.generation_rules.customer_creation.options.optional'
          )
        }}
      </el-radio>
    </el-radio-group>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    customerCreation: {
      get() {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.customers.auto_customer_number_enabled'
        )
      },
      set(value) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.customers.auto_customer_number_enabled',
          value
        })
      }
    }
  }
}
</script>
