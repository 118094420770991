<template>
  <th-wrapper
    :title="$t('pages.settings.customers.customer_number_generator.title')"
    :info="
      $t('pages.settings.customers.customer_number_generator.instructions')
    "
  >
    <!-- Enable -->
    <div class="mb-4">
      <el-switch
        v-model="enableCustomerNumberGenerator"
        :active-text="enableCustomerNumberGeneratorSwitchLabel"
      >
        <el-input v-model="enableCustomerNumberGenerator" />
      </el-switch>
    </div>

    <!-- Auto id generator -->
    <auto-id-generator
      ref="auto-id-generator"
      :disabled="!enableCustomerNumberGenerator"
      :config-path="templatePath"
      :wanted-elements="elements"
    />
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import AutoIdGenerator from '../../../components/auto-id-generator'

export default {
  name: 'CustomerNumberGenerator',
  components: {
    AutoIdGenerator
  },
  data() {
    return {
      templatePath: 'customers.customer_number_template',
      switchPath: 'customers.generate_customer_number',
      elements: [
        'full_date',
        'year',
        'month',
        'day',
        'country',
        'text',
        'sequence',
        'dash',
        'branch'
      ]
    }
  },
  computed: {
    enableCustomerNumberGenerator: {
      get() {
        return get(
          this.$store.state.Config.clientAccountConfiguration,
          this.switchPath
        )
      },
      set(isEnabled) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: this.switchPath,
          value: isEnabled
        })
      }
    },
    enableCustomerNumberGeneratorSwitchLabel() {
      return this.enableCustomerNumberGenerator
        ? this.$t(
            'pages.settings.customers.customer_number_generator.switch.enabled'
          )
        : this.$t(
            'pages.settings.customers.customer_number_generator.switch.disabled'
          )
    }
  },
  methods: {
    validate() {
      return this.$refs['auto-id-generator'].validate()
    }
  }
}
</script>
