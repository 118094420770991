<template>
  <section>
    <customer-number-generator ref="customer-number-generator" />
    <customers-per-branch ref="customers-per-branch" />
    <generation-rules />
  </section>
</template>

<script>
import CustomerNumberGenerator from './components/customer-number-generator'
import CustomersPerBranch from './components/customers-per-branch'
import GenerationRules from './components/generation-rules'

export default {
  components: {
    CustomerNumberGenerator,
    CustomersPerBranch,
    GenerationRules
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.customers.title')
    }
  },
  methods: {
    async validate(cb) {
      const customerNumberGeneratorValid = await this.$refs[
        'customer-number-generator'
      ].validate()

      const customersPerBranchValid = await this.$refs[
        'customers-per-branch'
      ].validate()

      cb(customersPerBranchValid && customerNumberGeneratorValid)
    }
  }
}
</script>
