<template>
  <th-wrapper :title="$t('pages.settings.general.customers_per_branch.title')">
    <el-form ref="form" :model="form" :rules="rules">
      <!-- Activated -->
      <el-form-item prop="customerPerBranchEnabled" class="leading-normal">
        <el-switch
          v-model="customerPerBranchEnabled"
          :active-text="switchMessage"
        />
      </el-form-item>

      <!-- Default branch -->
      <el-form-item
        prop="defaultBranch"
        :label="
          $t('pages.settings.general.customers_per_branch.default_branch')
        "
        class="mb-0"
      >
        <el-select
          v-model="defaultBranch"
          v-cancel-read-only
          :disabled="!isAdmin"
          filterable
          clearable
          :placeholder="$t('common.interactions.selects.placeholder')"
        >
          <el-option
            v-for="item in branches"
            :key="item.id"
            :label="item.name || item.branch_number || '--'"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'

export default {
  name: 'CustomerPerBranch',
  data() {
    return {
      branches: [],
      showMessage: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'Auth/isAdmin',
      currentLocation: 'Config/getCurrentLocation'
    }),
    defaultBranch: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'customers.default_branch'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'customers.default_branch',
          value: newValue || null
        })
      }
    },
    switchMessage() {
      return this.customerPerBranchEnabled
        ? this.$t(
            'pages.settings.general.customers_per_branch.switch.label_enabled'
          )
        : this.$t(
            'pages.settings.general.customers_per_branch.switch.label_disabled'
          )
    },
    customerPerBranchEnabled: {
      get() {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'customers.customers_per_branch'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'customers.customers_per_branch',
          value: newValue
        })
      }
    },
    form() {
      return {
        defaultBranch: this.defaultBranch,
        customerPerBranchEnabled: this.customerPerBranchEnabled
      }
    },
    rules() {
      return {
        defaultBranch: [
          {
            required: this.isDefaultBranchRequired,
            message: this.$t(
              'pages.settings.general.customers_per_branch.rules.default_branch.required'
            ),
            trigger: 'change'
          }
        ]
      }
    },
    isDefaultBranchRequired() {
      const template =
        safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'customers.customer_number_template'
        ) || ''
      return template.includes('branch')
    }
  },
  watch: {
    currentLocation(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.defaultBranch = newValue
      }
    },
    isDefaultBranchRequired(newValue) {
      if (newValue) {
        this.customerPerBranchEnabled = true
      }
    }
  },
  async created() {
    try {
      const { branchesV1 } = await this.$resourceFetch('branchesV1')
      this.branches = branchesV1
    } catch (err) {
      this.$notify.error({
        title: 'Error',
        message: err.message
      })
    }
  },
  mounted() {
    if (this.currentLocation) {
      this.defaultBranch = this.currentLocation
    }
  },
  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
